var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "planDay" },
    _vm._l(_vm.planDays, function (item, index) {
      return _c(
        "div",
        { key: index, staticStyle: { display: "flex", width: "100%" } },
        [
          _c("div", { staticClass: "day" }, [
            _c("div", { staticClass: "day dayDiv" }, [
              _c("img", { attrs: { src: _vm.image } }),
              _c("span", [_vm._v("D" + _vm._s(item.planDay))]),
            ]),
            _c("div", { staticClass: "line" }),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                width: "100%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    padding: "10px",
                    "padding-top": "5px",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "rgba(104,104,104,100)",
                        "font-weight": "700",
                        "font-size": "0.30rem",
                      },
                    },
                    [_vm._v(_vm._s(_vm._f("dateFormat")(item.planDate)))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mg-t-5",
                      staticStyle: {
                        "background-color": "#FFFFFF",
                        height: "0.7rem",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                        "padding-left": "10px",
                        "padding-right": "10px",
                        "border-radius": "5px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "0.30rem",
                            "font-weight": "700",
                            width: "68%",
                            "border-right": "1px dashed rgba(104,104,104,100)",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              [
                                ...new Set(
                                  item.cityList.map((x) => x.cityName)
                                ),
                              ].join("-")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            width: "32%",
                            "padding-left": "5px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "700",
                                color: "rgba(198,51,137,100)",
                              },
                            },
                            [
                              _vm._t("headerPrice", null, {
                                planDayData: item,
                                day: item.planDay,
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "padding-bottom": "10px",
                    "padding-left": "10px",
                    "padding-right": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _vm._t("planDay", null, {
                        planDayData: item,
                        day: item.planDay,
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }