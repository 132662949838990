var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "订单钱包支付审批详情",
          "left-text": "返回",
          "left-arrow": "",
        },
      }),
      _c(
        "div",
        { staticClass: "main", style: { height: _vm.mainHeight + "px" } },
        [
          _c(
            "div",
            {
              staticClass: "mainTop",
              staticStyle: { "background-color": "rgba(245, 245, 245, 100)" },
            },
            [
              _c("p", { staticClass: "mainTopText1" }, [
                _vm._v(_vm._s(_vm.datas.planName)),
              ]),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "p",
                {
                  staticClass: "orderMainIdNumber",
                  staticStyle: { "margin-right": "0.3rem" },
                },
                [_vm._v("出发时间：" + _vm._s(_vm.datas.departureTime))]
              ),
              _c("p", { staticClass: "orderMainIdNumber" }, [
                _vm._v("行程编号："),
                _c(
                  "span",
                  { staticStyle: { color: "#CE5F3A", "font-weight": "bold" } },
                  [_vm._v(_vm._s(_vm.datas.planId))]
                ),
              ]),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "orderMainIdNumber",
              staticStyle: { "margin-bottom": "0.2rem" },
            },
            [
              _vm._v(
                "主订单号：" + _vm._s(_vm.datas.orderMainId) + " 下单人："
              ),
              _vm.datas.orderListResults &&
              _vm.datas.orderListResults.length > 0
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#CE5F3A", "font-weight": "bold" },
                    },
                    [_vm._v(_vm._s(_vm.datas.orderListResults[0].createName))]
                  )
                : _vm._e(),
            ]
          ),
          _c("PlanDay", {
            attrs: { planDays: _vm.planDays },
            scopedSlots: _vm._u([
              {
                key: "headerPrice",
                fn: function (planDay) {
                  return [
                    _c("div", [
                      _c("span", { staticStyle: { "font-size": "0.30rem" } }, [
                        _vm._v(_vm._s(_vm.totalPriceByDay(planDay))),
                      ]),
                      _c("span", { staticStyle: { "font-size": "0.20rem" } }, [
                        _vm._v("¥"),
                      ]),
                      _c("span", { staticStyle: { "font-size": "0.20rem" } }, [
                        _vm._v("/天"),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "planDay",
                fn: function (planDay) {
                  return _vm._l(
                    planDay.planDayData.orderListResults,
                    function (items, indexs) {
                      return _c("childrenOrderContent", {
                        key: indexs,
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: {
                          items: items,
                          orderListResults:
                            planDay.planDayData.orderListResults,
                          resourceName: items.resourceName,
                          typeStr: _vm.typeNemeList2
                            ? _vm.typeNemeList2[items.state].typeNameCn
                            : "",
                        },
                      })
                    }
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("p", { staticClass: "footerText1" }, [
          _vm._v("订单总金额："),
          _c("span", [_vm._v(_vm._s(_vm.totalPriceByOrder()) + "¥")]),
        ]),
        _c("p", { staticClass: "footerText1" }, [
          _vm._v("团组总收入："),
          _c("span", [_vm._v(_vm._s(_vm.totalIncome.totalIncomePrice) + "¥")]),
        ]),
        _c("p", { staticClass: "footerText1" }, [
          _vm._v("团组已支出："),
          _c("span", [
            _vm._v(_vm._s(_vm.totalIncome.totalExpenditurePrice) + "¥"),
          ]),
        ]),
        _vm.datas.schedule === 1
          ? _c(
              "div",
              { staticClass: "footerBtnBox" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    staticStyle: { "margin-right": "0.2rem" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.businessOpSaveH5 },
                  },
                  [_vm._v(_vm._s(_vm.datas.h5ConfigText))]
                ),
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", type: "danger" },
                    on: { click: _vm.rejectClick },
                  },
                  [_vm._v("驳回")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.datas.schedule === 2
          ? _c(
              "div",
              { staticClass: "footerBtnBox" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", type: "primary" },
                  },
                  [_vm._v("审核已通过")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.datas.schedule === 3
          ? _c(
              "div",
              { staticClass: "footerBtnBox1" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "footerBtns",
                    attrs: { size: "small", color: "#627177" },
                  },
                  [_vm._v("审核已驳回")]
                ),
                _c("p", [
                  _vm._v("驳回理由：" + _vm._s(_vm.datas.reasonForRejection)),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "van-dialog",
        {
          attrs: { title: "驳回理由", "show-cancel-button": "" },
          on: { confirm: _vm.confirm, cancel: _vm.cancel },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-field", {
            attrs: { placeholder: "请输入驳回理由" },
            model: {
              value: _vm.chunDatas.returnMsg,
              callback: function ($$v) {
                _vm.$set(_vm.chunDatas, "returnMsg", $$v)
              },
              expression: "chunDatas.returnMsg",
            },
          }),
        ],
        1
      ),
      _c(
        "van-dialog",
        {
          attrs: { title: "审批", "show-cancel-button": "" },
          on: { confirm: _vm.optionClick },
          model: {
            value: _vm.show1,
            callback: function ($$v) {
              _vm.show1 = $$v
            },
            expression: "show1",
          },
        },
        [
          _vm.show1
            ? _c("div", { staticClass: "shenheBox" }, [
                _c(
                  "div",
                  { staticClass: "shenheLine" },
                  [
                    _c("van-icon", {
                      staticStyle: { "margin-right": "0.1rem" },
                      attrs: { color: "#ED7046", name: "balance-o" },
                    }),
                    _c("div", { staticClass: "typeW" }, [_vm._v("钱包余额：")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.datas.paymentApprovalQueryResult.walletBalance
                        ) + "¥"
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "shenheLine" },
                  [
                    _c("van-icon", {
                      staticStyle: { "margin-right": "0.1rem" },
                      attrs: { color: "#ED7046", name: "balance-o" },
                    }),
                    _c("div", { staticClass: "typeW" }, [
                      _vm._v("专项款余额："),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.datas.paymentApprovalQueryResult
                            .balanceOfSpecialFunds
                        ) + "¥"
                      ),
                    ]),
                  ],
                  1
                ),
                JSON.stringify(_vm.datas) !== "{}"
                  ? _c(
                      "div",
                      { staticClass: "shenheLine" },
                      [
                        _c("van-icon", {
                          staticStyle: { "margin-right": "0.1rem" },
                          attrs: { color: "#ED7046", name: "refund-o" },
                        }),
                        _c("div", { staticClass: "typeW" }, [
                          _vm._v("待减余额："),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.payPrice()) + "¥")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }