<template>
    <div class="planDay">
        <div style="display: flex;width: 100%" v-for="(item,index) in planDays" :key="index">
            <div class="day">
                <div class="day dayDiv">
                    <img :src="image"/>
                    <span>D{{item.planDay}}</span>
                </div>
                <div class="line"></div>
            </div>
            <div style="display: flex;flex-direction: column;width: 100%">
                <div style="width: 100%;padding: 10px;padding-top: 5px">
                    <span style="color: rgba(104,104,104,100);font-weight: 700;font-size: 0.30rem">{{item.planDate | dateFormat}}</span>
                    <div class="mg-t-5" style="background-color: #FFFFFF;height: 0.7rem;display: flex;align-items: center;justify-content: space-between;padding-left: 10px;padding-right: 10px;border-radius: 5px">
                        <div style="font-size: 0.30rem;font-weight: 700;width: 68%;border-right: 1px dashed rgba(104,104,104,100)">{{ [...new Set(item.cityList.map(x => x.cityName))].join("-") }}</div>
                        <div style="display: flex;justify-content: center;width: 32%;padding-left: 5px">
                            <div style="font-weight: 700;color: rgba(198,51,137,100);">
                                <slot name="headerPrice" :planDayData="item" :day="item.planDay"></slot>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;padding-bottom: 10px;padding-left: 10px;padding-right: 10px">
                    <div>
                        <slot name="planDay" :planDayData="item" :day="item.planDay"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACUklEQVRYR+2Xy2vUUBTGv3NnyhSlUrSVJOMjxZV20UL/gSkW7KRV3Lh2Vt0LdW3Fre6tG3Wj0KXTTkpRO3sf4EaKVDqMTjKtKCoo83ByJKHJvEp7pwSmi7kQuLm533d+OfcmOSF0uVGX4+NAgC/TSqLP4TfK2vYfWdiNa0MDxyvRCRndvgDWjDoHxqLDPH/GLD6QBfhqKCkBeiyj2xegYCgLBLrD4LvxTHFBFqATXRvA1nV9MFoqjbvBhMBNAqUY/MRx8FQWYC/dMaq8P2X++N3q0QRgGdoSwDdkA3U6j8DpKju3zpk7n31tAGAZKgeGjOxuXwdBByMHeIdsa9cREr6YBc7Hl+28e+4BWEl1EYQ5d62JKK2t2O/c8U7WspFsL509reiOQMrdUwCtaBlrtg5gqDsAhol5VDWLH32zb1eG1Kroe8ZEj+IZ67ns7duzisE1ut2qcx/piKB1L7DDI+pqMUfBICOrmfakbJDDzrOS6joIiZrDk2dXi9kAgEEfQJg/rLG0jnGfwGNtANIGIU30X1L1JQD9BHiwwf9lSLF8m6ndjuO+Yir/nMv62vbro7MH0O1N2APoZaCXgV4GupaB78mTJ8oU+9X4jQ75GxDYWTPqBBhvvYEIXdTS1oZXEdmG8oJBV1srojBBmisifCr/jY2NZHMlDyCfPH0hSpHNIGC9JgyPoaEmFEJMKcuFV0FJ5mUhqVyCoCVmjIYXtdmJiC1muqdl7If+laayfCuh98cGKnqt6ihhQ8Si0cJwrT9P5ma50fvAf8OwQVr9ug7wH/8HmjAEHOudAAAAAElFTkSuQmCC",
        }
    },
    props: {
        planDays: {
            type: Array,
            default: () => {
                return []
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.planDay {
    padding: 0.05rem;
    background-color: rgba(245, 245, 245, 100);
    height: 100%;
}

.day {
    position: relative;
    display: flex;
    flex-direction: column;
    > img {
        height: 25px;
        width: 25px;
    }

    > span {
        position: absolute;
        color: rgba(213, 86, 47, 100);
        font-weight: 700;
        font-size: 12px;
        left: 5px;
        top: 10px;
    }
}

.line {
    border-left: 0.03rem dashed rgba(213, 86, 47, 100);
    min-height: 1rem;
    margin: 0 auto;
    height: 100%;
}
.mg-t-5 {
    margin-top: 5px;
}
</style>