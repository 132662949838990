<template>
    <div class='container'>
        <van-collapse :border="false" v-model="activeNames">
            <van-collapse-item :name="items.orderChildrenId">
                <template #title>
                    <p style="word-wrap: break-word;word-break: break-all;overflow: hidden;">
                        <span>{{typeNemeList2 ? typeNemeList2[items.state].typeNameCn : ''}}</span>
                        <span style="color: black;margin-left: 10px">{{items.resourceName}}</span>
                        <span><span style="color: black;margin-left: 10px"></span>{{totalPriceByOrderChildren(items)}}¥</span>
                    </p>
                </template>
                <div class="goodsBox" v-for="(item, index) in items.orderListDetailResultList" :key="index" style="box-shadow: rgba(0, 0, 0, 0.08) 1.5px 1.5px 1px;">
                    <p class="goodsBoxText1" style="margin-bottom: 0;padding-top: 0.2rem">{{ item.productName }}</p>
                    <p class="goodsBoxText1" style="margin-left: 0;word-wrap: break-word;word-break: break-all;overflow: hidden;padding-bottom: 0.1rem">
                        <span style="margin-left: 0"><span style="color: black;margin-left: 0">单价:</span>{{ item.currency }}{{ item.price }}</span>
                        <span style="margin-left: 10px"><span style="color: black;margin-left: 0">数量:</span>{{ item.number }}<span v-if="item.priceCompany">{{
                                item.priceCompany }}</span></span>
                        <span style="margin-left: 10px"><span style="color: black;margin-left: 0">总价:</span>{{
                                totalCurrencyPrice(item) }}{{ item.currency }}</span>
                    </p>
                </div>
            </van-collapse-item>
        </van-collapse>
    </div>
</template>
import { days, getUrlParams, typeNemeList2, addOneDay } from '@/utils/unit'
<script>
import {addOneDay, days,typeNemeList2} from "@/utils/unit";

export default {
    data() {
        return {
            typeNemeList2,
            addOneDay,
            days,
            activeNames: [],
        }
    },
    mounted() {
        if (this.orderListResults) {
            this.orderListResults.forEach(item => {
                this.activeNames.push(item.orderChildrenId)
            })
            console.log("activeNames",this.activeNames,this.orderListResults)
        }
    },
    computed: {
        // 每条子订单的总价格
        totalPriceByOrderChildren() {
            return function (items) {
                let price = 0
                items.orderListDetailResultList.forEach(n => {
                    price += n.price * n.number * n.rate
                })
                return price.toFixed(2)
            }
        },
        //计算总价钱
        totalPrice() {
            return function (n) {
                let price = 0
                price += n.price * n.number * n.rate
                return Number(price.toFixed(2))
            }
        },
        //计算原币价格
        totalCurrencyPrice() {
            return function (n) {
                let price = 0
                price += n.price * n.number
                return Number(price.toFixed(2))
            }
        }
    },
    props: {
        items: {
            type: Object,
            default: {}
        },
        orderListResults: {
            type: Array,
            default: () => {
                return []
            }
        }
    }
}
</script>
<style lang='scss' scoped>
/deep/ .van-cell__title {
    display: flex;
    align-items: center;
}

/deep/ .van-cell__title .van-cell__label {
    margin-top: 0;
    margin-left: 0.1rem;
    font-weight: 700;
    font-size: 0.26rem;
    color: black;
}

/deep/ .van-collapse-item__content {
    padding-top: 0;
}

/deep/ .van-cell__title {
    color: #CE5F3A !important;
}

/deep/ .van-cell__left-icon {
    color: red;
}

/deep/.van-cell--clickable {
    font-size: 0.26rem;
    font-weight: 700;
    color: black;
}

.container {
    .goodsBox {
        padding-left: 15px;
        .goodsBoxText1 {
            color: #333333;
            font-size: 0.27rem;
            font-family: SourceHanSansSC-bold;
            font-weight: bold;
            margin-bottom: 0.1rem;

            span {
                font-size: 0.25rem;
                margin-left: 0.1rem;
                color: #CE5F3A;
            }
        }

        .goodsBoxText2 {
            color: #333333;
            font-size: 0.27rem;
            font-family: SourceHanSansSC-bold;
            font-weight: bold;

            span {
                font-size: 0.25rem;
            }

            .span1 {
                color: #CE5F3A;
                margin-left: 0.1rem;
            }

            .span2 {
                color: red;
                font-size: 0.27rem;
            }
        }
    }
}
</style>
