<template>
    <div class='container'>
        <van-nav-bar title="订单钱包支付审批详情" left-text="返回" left-arrow> </van-nav-bar>
        <div class="main" :style="{ height: mainHeight + 'px' }">
            <div class="mainTop" style="background-color: rgba(245, 245, 245, 100)">
                <p class="mainTopText1">{{ datas.planName }}</p>
            </div>
            <div style="display: flex;align-items: center">
                <p class="orderMainIdNumber" style="margin-right: 0.3rem;">出发时间：{{ datas.departureTime }}</p>
                <p class="orderMainIdNumber">行程编号：<span style="color: #CE5F3A;font-weight: bold">{{ datas.planId }}</span></p>
            </div>
            <p class="orderMainIdNumber" style="margin-bottom: 0.2rem;">主订单号：{{ datas.orderMainId }} 下单人：<span v-if="datas.orderListResults && datas.orderListResults.length > 0" style="color: #CE5F3A;font-weight: bold">{{ datas.orderListResults[0].createName }}</span></p>
            <PlanDay :planDays="planDays">
                <!--每一天的总价格-->
                <template #headerPrice="planDay">
                    <div>
                        <span style="font-size: 0.30rem;">{{totalPriceByDay(planDay)}}</span>
                        <span style="font-size: 0.20rem">¥</span>
                        <span style="font-size: 0.20rem">/天</span>
                    </div>
                </template>
                <template #planDay="planDay">
                    <childrenOrderContent
                        v-for="(items, indexs) in planDay.planDayData.orderListResults" :key="indexs"
                        style="margin-bottom: 10px"
                        :items="items"
                        :orderListResults="planDay.planDayData.orderListResults"
                        :resourceName="items.resourceName"
                        :typeStr="typeNemeList2 ? typeNemeList2[items.state].typeNameCn : ''"
                    >
                    </childrenOrderContent>
                </template>
            </PlanDay>
        </div>
        <div class="footer">
            <p class="footerText1">订单总金额：<span>{{ totalPriceByOrder() }}¥</span></p>
            <p class="footerText1">团组总收入：<span>{{ totalIncome.totalIncomePrice }}¥</span></p>
            <p class="footerText1">团组已支出：<span>{{ totalIncome.totalExpenditurePrice }}¥</span></p>
            <!-- <p class="footerText1">利润：<span v-if="JSON.stringify(datas) !== '{}'">{{ payPrice() }}¥</span></p> -->
            <div class="footerBtnBox" v-if="datas.schedule === 1">
                <van-button class="footerBtns" @click="businessOpSaveH5" style="margin-right: 0.2rem;" size="small"
                            type="primary">{{
                        datas.h5ConfigText }}</van-button>
                <van-button @click="rejectClick" class="footerBtns" size="small" type="danger">驳回</van-button>
            </div>
            <div v-if="datas.schedule === 2" class="footerBtnBox">
                <van-button class="footerBtns" size="small" type="primary">审核已通过</van-button>
            </div>
            <div v-if="datas.schedule === 3" class="footerBtnBox1">
                <van-button class="footerBtns" size="small" color="#627177">审核已驳回</van-button>
                <p>驳回理由：{{ datas.reasonForRejection }}</p>
            </div>
        </div>
        <van-dialog @confirm="confirm" @cancel="cancel" v-model="show" title="驳回理由" show-cancel-button>
            <van-field v-model="chunDatas.returnMsg" placeholder="请输入驳回理由" />
        </van-dialog>
        <van-dialog @confirm="optionClick" v-model="show1" title="审批" show-cancel-button>
            <div class="shenheBox" v-if="show1">
                <div class="shenheLine">
                    <van-icon color="#ED7046" style="margin-right: 0.1rem;" name="balance-o" />
                    <div class="typeW">钱包余额：</div> <span>{{ datas.paymentApprovalQueryResult.walletBalance }}¥</span>
                </div>
                <div class="shenheLine">
                    <van-icon color="#ED7046" style="margin-right: 0.1rem;" name="balance-o" />
                    <div class="typeW">专项款余额：</div> <span>{{ datas.paymentApprovalQueryResult.balanceOfSpecialFunds
                    }}¥</span>
                </div>
                <div class="shenheLine" v-if="JSON.stringify(datas) !== '{}'">
                    <van-icon color="#ED7046" style="margin-right: 0.1rem;" name="refund-o" />
                    <div class="typeW">待减余额：</div> <span>{{ payPrice() }}¥</span>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import PayApi from "@/components/Api/pay";
import ApplyApi from "@/components/Api/apply";
import { Toast } from 'vant';
import { days, getUrlParams, typeNemeList2, addOneDay } from '@/utils/unit'
import PlanDay from "@/components/PlanDay.vue";
import childrenOrderContent from "@/views/order/childrenOrderContent.vue";
import OrderApi from "@/components/Api/order";
export default {
    components: { PlanDay,childrenOrderContent },
    data() {
        return {
            planDays: [],
            activeNames: [],
            mainHeight: 0,
            typeNemeList2,
            addOneDay,
            datas: {},//渲染的数据
            totalIncome:{},
            days,
            show: false,
            show1: false,
            title: '',
            chunDatas: {
                returnMsg: ''
            }
        }
    },
    computed: {
        // 行主订单的总金额
        totalPriceByOrder() {
            return function () {
                let price = 0
                this.planDays.forEach(s => {
                    s.orderListResults.forEach(u => {
                        u.orderListDetailResultList.forEach(n => {
                            price += n.price * n.number * n.rate
                        })
                    })
                })
                return price.toFixed(2)
            }
        },
        // 每一天的总价格
        totalPriceByDay() {
            return function (planDay) {
                let price = 0
                planDay.planDayData.orderListResults.forEach(u => {
                    u.orderListDetailResultList.forEach(n => {
                        price += n.price * n.number * n.rate
                    })
                })
                return price.toFixed(2)
            }
        },
        //计算总价钱
        totalPrice() {
            return function (n) {
                let price = 0
                price += n.price * n.number * n.rate * (this.days(n.startTime, n.endTime) + 1)
                return price.toFixed(2)
            }
        },
        payPrice() {
            return function () {
                let price = 0
                this.datas.orderListResults.forEach(item => {
                    item.orderListDetailResultList.forEach(item1 => {
                        price = Number(price) + Number(this.totalPrice(item1))
                    })
                })
                return price.toFixed(2)
            }
        }
    },
    watch: {},
    methods: {
        getDatas() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let obj = {
                orderMainId: this.chunDatas.orderMainId,
                configId: this.chunDatas.configId,
                userId: this.chunDatas.userId
            }
            new PayApi("pay").queryAndApproveOrders(obj).then(res => {
                console.log(res, '看看返回的数据');
                if (res.status == 200) {
                    this.datas = res.data
                    this.getPlanTotalIncome(this.datas.planId)
                    // 获取行程周内的数据
                    new OrderApi("order").orderPlanData(this.datas.orderMainId).then(res => {
                        console.log(res.data,"orderPlan")
                        if (res.status === 200) {
                            this.planDays = res.data.planDays
                        }
                    })
                    setTimeout(() => {
                        const footerHeight = this.$el.querySelector('.footer').clientHeight;
                        this.mainHeight = window.innerHeight - footerHeight - 46;
                    })
                    this.datas.orderListResults.forEach(item => {
                        this.activeNames = [...this.activeNames, ...item.orderListDetailResultList.map(n => n.id)]
                    })
                    Toast.clear();
                } else {
                    Toast(res.statusText);
                }
            })
        },
        getPlanTotalIncome(planId) {
            new PayApi("pay").getPlanTotalIncomeHttp(planId).then(res => {
                if (res.status == 200) {
                    this.totalIncome = res.data
                } else {
                    Toast(res.statusText);
                }
            })
        },
        optionClick() {
            new ApplyApi("apply").businessOpSaveH5(this.chunDatas).then(res => {
                Toast.loading({
                    message: '审批中...',
                    forbidClick: true,
                });
                if (res.status === 200) {
                    new PayApi("pay").getById(this.datas.paymentApprovalQueryResult, this.chunDatas.orderMainId).then(res => {
                        if (res.status == 200) {
                            Toast("审批成功...");
                            this.getDatas()
                        } else {
                            Toast(res.statusText);
                        }
                    })
                }
            })
        },
        //驳回
        rejectClick() {
            this.show = true

        },
        businessOpSaveH5() {
            this.show1 = true
        },
        //确认
        confirm() {
            new ApplyApi("apply").businessOpRejectH5(this.chunDatas).then(res => {
                Toast.loading({
                    message: '驳回中...',
                    forbidClick: true,
                });
                if (res.status === 200) {
                    new PayApi("pay").getById(this.datas.paymentApprovalQueryResult, this.chunDatas.orderMainId).then(res => {
                        if (res.status == 200) {
                            Toast("驳回成功...");
                            this.getDatas()
                        } else {
                            Toast(res.statusText);
                        }
                    })
                }
            })
        },
        //取消
        cancel() {
        },
    },
    created() {

    },
    mounted() {
        this.chunDatas = {
            orderMainId: getUrlParams('orderMainId'),
            configId: getUrlParams('configId'),
            userId: getUrlParams('userId'),
            step: getUrlParams('step'),
            approvalBusinessId: getUrlParams('approvalBusinessId'),
        }
        this.getDatas()
        console.log(this.chunDatas, '穿过来的数据');
    },
}
</script>
<style lang='scss' scoped>
/deep/ .van-cell__title {
    display: flex;
    align-items: center;
}

/deep/ .van-cell__title .van-cell__label {
    margin-top: 0;
    margin-left: 0.1rem;
}

/deep/ .van-cell__left-icon {
    color: red;
}

.container {
    .shenheBox {
        padding: 0.3rem;

        .shenheLine {
            display: flex;
            align-items: center;
            margin-bottom: 0.1rem;

            .typeW {
                font-size: 0.27rem;
                color: #000;
                width: 1.8rem;
            }

            span {
                color: red;
                font-size: 0.27rem;
            }
        }
    }

    .main {
        padding: 0.2rem;
        overflow-y: scroll;
        background-color: rgba(245, 245, 245, 100);
        .mainTop {
            margin-bottom: 0.1rem;
            display: flex;
            align-items: center;

            .mainTopText1 {
                font-size: 0.37rem;
                margin-right: 0.1rem;
                font-weight: bold;


            }

            .mainTopText2 {
                font-family: 思源宋体 Medium;
                font-size: 0.2rem;
                color: #838383;
            }
        }

        .orderMainIdNumber {
            font-family: 思源宋体 Medium;
            font-size: 0.2rem;
            color: #838383;
            margin-bottom: 0.1rem;
        }

        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 0.1rem;
            margin-bottom: 0.3rem;

            .cardTop {
                display: flex;
                align-items: center;
                margin-bottom: 0.1rem;

                .typeBtn {
                    width: 0.8rem;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    border-radius: 0.08rem;
                    background-color: #DE562C;
                    color: white;
                    font-size: 0.12rem;
                    text-align: center;
                    font-family: PingFang SC;
                    margin-right: 0.12rem;
                }

                .productName {
                    color: #006FFF;
                    font-size: 0.27rem;
                    font-family: SourceHanSansSC-bold;
                }


            }

            .infoBox {
                .infoBoxCom {
                    font-size: 0.35rem;
                    font-family: cursive;
                    font-weight: bold;
                    margin-bottom: 0.1rem;
                }

                .orderNumber {
                    font-size: 0.25rem;
                    font-family: SourceHanSansSC-bold;
                    font-weight: bold;
                }
            }

            .goodsBox {
                .goodsBoxText1 {
                    color: #333333;
                    font-size: 0.27rem;
                    font-family: SourceHanSansSC-bold;
                    font-weight: bold;
                    margin-bottom: 0.1rem;

                    span {
                        font-size: 0.25rem;
                        margin-left: 0.1rem;
                        color: #CE5F3A;
                    }
                }

                .goodsBoxText2 {
                    color: #333333;
                    font-size: 0.27rem;
                    font-family: SourceHanSansSC-bold;
                    font-weight: bold;

                    span {
                        font-size: 0.25rem;
                    }

                    .span1 {
                        color: #CE5F3A;
                        margin-left: 0.1rem;
                    }

                    .span2 {
                        color: red;
                        font-size: 0.27rem;
                    }
                }
            }
        }
    }

    .footerText1 {
        text-align: left;
        color: #333333;
        font-size: 0.32rem;
        font-family: SourceHanSansSC-bold;
        font-weight: bold;
        margin-bottom: 0.1rem;

        span {
            color: red;
        }
    }

    .footer {
        padding: 0.35rem;
        width: 100%;



        .footerBtnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footerBtns {
                width: 100%;
            }
        }

        .footerBtnBox1 {
            display: flex;
            align-items: center;
            flex-direction: column;

            .footerBtns {
                margin-bottom: 0.1rem;
                width: 100%;
            }
        }
    }
}
</style>
