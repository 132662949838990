var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "van-collapse",
        {
          attrs: { border: false },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "van-collapse-item",
            {
              attrs: { name: _vm.items.orderChildrenId },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "word-wrap": "break-word",
                            "word-break": "break-all",
                            overflow: "hidden",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.typeNemeList2
                                  ? _vm.typeNemeList2[_vm.items.state]
                                      .typeNameCn
                                  : ""
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "black",
                                "margin-left": "10px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.items.resourceName))]
                          ),
                          _c("span", [
                            _c("span", {
                              staticStyle: {
                                color: "black",
                                "margin-left": "10px",
                              },
                            }),
                            _vm._v(
                              _vm._s(_vm.totalPriceByOrderChildren(_vm.items)) +
                                "¥"
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            _vm._l(_vm.items.orderListDetailResultList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "goodsBox",
                  staticStyle: {
                    "box-shadow": "rgba(0, 0, 0, 0.08) 1.5px 1.5px 1px",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "goodsBoxText1",
                      staticStyle: {
                        "margin-bottom": "0",
                        "padding-top": "0.2rem",
                      },
                    },
                    [_vm._v(_vm._s(item.productName))]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "goodsBoxText1",
                      staticStyle: {
                        "margin-left": "0",
                        "word-wrap": "break-word",
                        "word-break": "break-all",
                        overflow: "hidden",
                        "padding-bottom": "0.1rem",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "margin-left": "0" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "black", "margin-left": "0" },
                          },
                          [_vm._v("单价:")]
                        ),
                        _vm._v(_vm._s(item.currency) + _vm._s(item.price)),
                      ]),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "black", "margin-left": "0" },
                          },
                          [_vm._v("数量:")]
                        ),
                        _vm._v(_vm._s(item.number)),
                        item.priceCompany
                          ? _c("span", [_vm._v(_vm._s(item.priceCompany))])
                          : _vm._e(),
                      ]),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "black", "margin-left": "0" },
                          },
                          [_vm._v("总价:")]
                        ),
                        _vm._v(
                          _vm._s(_vm.totalCurrencyPrice(item)) +
                            _vm._s(item.currency)
                        ),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }